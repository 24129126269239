const fns = require('date-fns')
const geofire = require('geofire-common')

/**
 * @type {firebase.FieldValue}
 */
let _FieldValue

/**
 * @type {firebase.firestore.Firestore}
 */
let _firestore

let _isWebContext

let _webFirestore

const _adminBasePath = 'SERVICE/spotwork-admin'
const _businessBasePath = 'SERVICE/business'
const _partnerBasePath = 'SERVICE/partner'

/**
 * @typedef IUserModel
 * @property {string} id
 */

/**
 * @typedef IWorkModel
 * @property {string} id
 * @property {string} status
 * @property {string | undefined} orderId
 * @property {string} customer
 * @property {number | undefined} price
 * @property {import("firebase-admin").firestore.Timestamp | undefined} reWorkDeadline
 * @property {{import("firebase-admin").firestore.Timestamp} | undefined} schedulingDate
 * @property {string | undefined} reviewingComment
 * @property {string | undefined} approvalStatus
 */

/**
 * @typedef IInsertErrorReportFormCollectionModel
 * @property {string} id
 */

/**
 * @typedef ITrackingLogModel
 * @property {string} id
 */

/**
 * @typedef IHistoryModel
 * @property {string} id
 */

/**
 * @typedef ISecretModel
 * @property {string} id
 */

/**
 * @typedef ILocationtModel
 * @property {string} id
 */

/**
 * @typedef IRecentWorkModel
 * @property {string} id
 */

/**
 * @typedef IReceivedBatteryLogModel
 * @property {string} id
 */

/**
 * @typedef ICurrentWorkModel
 * @property {string} id
 */

/**
 * @typedef IReservedSpotModel
 * @property {string} id
 */

/**
 * @typedef IOrderModel
 * @property {string} id
 * @property {number?} lat
 * @property {number?} lng
 * @property {string?} geoHash
 */

/**
 * @interface BaseCollection
 * @template TModel
 */
class BaseCollection {
  /**
   * @returns {firebase.firestore.CollectionReference}
   */

  _ref = ''

  get collection() {
    return this.getCollection()
  }

  getCollection() {
    if (_isWebContext) {
      return _webFirestore.collection(_firestore, this._ref)
    } else {
      return _firestore.collection(this._ref)
    }
  }

  getCollectionGroup() {
    if (_isWebContext) {
      return _webFirestore.collectionGroup(_firestore, this._ref)
    } else {
      return _firestore.collectionGroup(this._ref)
    }
  }

  getDocRef(id) {
    if (_isWebContext) {
      if (!id || id === '') {
        return null
      }
      return _webFirestore.doc(this.collection, id)
    } else {
      return this.collection.doc(id)
    }
  }

  getNewDocId() {
    if (_isWebContext) {
      return _webFirestore.doc(this.collection).id
    } else {
      return this.collection.doc().id
    }
  }

  /**
   * @param {FirebaseFirestore.DocumentSnapshot<FirebaseFirestore.DocumentData>} doc
   * @returns {TModel}
   */
  getDataFromDocumentData(doc) {
    const isDocExist = _isWebContext ? doc.exists() : doc.exists
    if (isDocExist) {
      return {
        ...doc.data(),
        id: doc.id
      }
    } else {
      return null
    }
  }

  getAllDataFromDocs(docs) {
    return docs.map((d) => this.getDataFromDocumentData(d))
  }

  /**
   * @returns {Promise<TModel[]>}
   */
  all() {
    if (_isWebContext) {
      return _webFirestore
        .getDocs(this.collection)
        .then((q) => q.docs.map(this.getDataFromDocumentData))
    } else {
      return this.collection
        .get()
        .then((q) => q.docs.map(this.getDataFromDocumentData))
    }
  }

  /**
   * @returns {Promise<string[]>}
   */
  allIds() {
    if (_isWebContext) {
      return _webFirestore
        .getDocs(this.collection)
        .then((q) => q.docs.map((item) => item.id))
    } else {
      return this.collection.get().then((q) => q.docs.map((item) => item.id))
    }
  }

  getRawDocsByQuery(query) {
    if (_isWebContext) {
      return _webFirestore.getDocs(query)
    } else {
      return query.get()
    }
  }

  getByQuery(query) {
    if (_isWebContext) {
      return _webFirestore
        .getDocs(query)
        .then((q) => q.docs.map(this.getDataFromDocumentData))
    } else {
      return query.get().then((q) => q.docs.map(this.getDataFromDocumentData))
    }
  }

  getDocByQuery(query) {
    if (_isWebContext) {
      return _webFirestore.getDocs(query).then((q) => {
        if (q.empty) return null
        return this.getDataFromDocumentData(q.docs[0])
      })
    } else {
      return query.get().then((q) => {
        if (q.empty) return null
        return this.getDataFromDocumentData(q.docs[0])
      })
    }
  }

  generateQuery(
    queries = null,
    orders = null,
    limitNumber = null,
    startAt = null,
    endAt = null
  ) {
    if (_isWebContext) {
      const injectedQueries = []
      if (queries) {
        queries.forEach((q) => {
          injectedQueries.push(
            _webFirestore.where(q.field, q.operator, q.value)
          )
        })
      }
      if (orders) {
        orders.forEach((order) => {
          injectedQueries.push(
            _webFirestore.orderBy(order.field, order.direction)
          )
        })
      }
      if (limitNumber) {
        injectedQueries.push(_webFirestore.limit(limitNumber))
      }
      if (startAt) {
        injectedQueries.push(_webFirestore.startAt(startAt))
      }
      if (endAt) {
        injectedQueries.push(_webFirestore.endAt(endAt))
      }
      return _webFirestore.query(this.collection, ...injectedQueries)
    } else {
      let query = this.collection
      if (queries) {
        queries.forEach((q) => {
          query = query.where(q.field, q.operator, q.value)
        })
      }
      if (orders) {
        orders.forEach((order) => {
          query = query.orderBy(order.field, order.direction)
        })
      }
      if (limitNumber) {
        query = query.limit(limitNumber)
      }
      if (startAt) {
        query = query.startAt(startAt)
      }
      if (endAt) {
        query = query.endAt(endAt)
      }
      return query
    }
  }

  /**
   * @param {string} id
   * @returns {Promise<TModel>}
   */
  findById(id) {
    if (_isWebContext) {
      if (!id || id === '') {
        console.error('error: id is null or empty', this.findById.name)
        return null
      }
      return _webFirestore
        .getDoc(_webFirestore.doc(this.collection, id))
        .then(this.getDataFromDocumentData)
    } else {
      return this.collection.doc(id).get().then(this.getDataFromDocumentData)
    }
  }

  /**
   * @param {TModel} obj
   * @returns {Promise<TModel>}
   */
  add(obj) {
    if (_isWebContext) {
      removeUndefinedProperties(obj)
      return _webFirestore
        .addDoc(this.collection, obj)
        .then((q) => _webFirestore.getDoc(q))
        .then(this.getDataFromDocumentData)
    } else {
      return this.collection
        .add(obj)
        .then((q) => q.get())
        .then(this.getDataFromDocumentData)
    }
  }

  /**
   * @param {string} id
   * @param {TModel} obj
   * @returns {Promise<void>}
   */
  set(id, obj) {
    if (_isWebContext) {
      removeUndefinedProperties(obj)
      return _webFirestore.setDoc(_webFirestore.doc(this.collection, id), obj)
    } else {
      return this.collection.doc(id).set(obj)
    }
  }

  /**
   * @param {string} id
   * @param {TModel} obj
   * @returns {Promise<void>}
   */
  update(id, obj) {
    if (_isWebContext) {
      removeUndefinedProperties(obj)
      return _webFirestore.updateDoc(
        _webFirestore.doc(this.collection, id),
        obj
      )
    } else {
      return this.collection.doc(id).update(obj)
    }
  }

  /**
   * @param {string} id
   * @returns {Promise<void>}
   */
  delete(id) {
    if (_isWebContext) {
      return _webFirestore.deleteDoc(_webFirestore.doc(this.collection, id))
    } else {
      return this.collection.doc(id).delete()
    }
  }

  /**
   * Return unsubscribe function
   * @param {string} id
   * @param {(object: TModel) => void} callback
   * @returns {() => void}
   */
  onUpdatedDoc(id, callback) {
    if (_isWebContext) {
      if (!id || id === '') {
        console.error('error: id is null or empty', this.onUpdatedDoc.name)
        return null
      }
      return _webFirestore.onSnapshot(
        _webFirestore.doc(this.collection, id),
        (doc) => {
          callback(this.getAllDataFromDocs(doc))
        }
      )
    } else {
      return this.collection.doc(id).onSnapshot((doc) => {
        let obj = this.getAllDataFromDocs(doc)
        callback(obj)
      })
    }
  }

  onUpdateDocs(query, callback) {
    if (_isWebContext) {
      return _webFirestore.onSnapshot(query, (snapshot) => {
        callback(this.getAllDataFromDocs(snapshot.docs))
      })
    } else {
      return query.onSnapshot((snapshot) => {
        callback(this.getAllDataFromDocs(snapshot.docs))
      })
    }
  }
}

// --- collectionGroupを使う場合はこちらを継承する ---
// collectionメンバをcollectionGroupに変更する
class BaseCollectionGroup extends BaseCollection {
  constructor() {
    super()
  }

  getCollection() {
    if (_isWebContext) {
      return _webFirestore.collectionGroup(_firestore, this._ref)
    } else {
      return _firestore.collectionGroup(this._ref)
    }
  }
}

/**
 * @extends {BaseCollection<IUserModel>}
 */
class UserCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'USER'
  }

  async transactionPoint(uid, number) {
    await this.update(uid, { point: increment(number) })
  }

  completeTutorialAdd(id) {
    return this.update(id, {
      completeTutorial: true
    })
  }

  AddGeolocationPermission(id, geolocationPermission) {
    return this.update(id, {
      geolocationPermission: arrayUnion(geolocationPermission)
    })
  }

  async getRecentLoginUsers(seconds) {
    const now = new Date()
    const queryTimeUnix = now.getTime() - seconds * 1000
    const queryTime = new Date(queryTimeUnix)
    const queries = [
      { field: 'lastSignInTime', operator: '>=', value: queryTime },
      { field: 'type', operator: 'array-contains', value: 'rounder' }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  async getBlockedType() {
    const queries = [
      { field: 'type', operator: 'array-contains', value: 'blocked' }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  async getBlackListPbType() {
    const queries = [
      { field: 'type', operator: 'array-contains', value: 'blackListPb' }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  async getUserByReadInfoId(infoId) {
    const queries = [
      { field: 'readInfo', operator: 'array-contains', value: infoId }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  async getByAccessibleUserId(accessibleUserId) {
    const queries = [
      { field: 'accessibleUserId', operator: '==', value: accessibleUserId }
    ]
    const query = this.generateQuery(queries)
    return this.getDocByQuery(query)
  }
}

class SecretCollectionGroup extends BaseCollectionGroup {
  constructor() {
    super()
    this._ref = 'SECRET'
  }

  /**
   * secretCollectionの中にキーvalueが存在したらそのuidを返す
   * @param {string} keyword 検索したいキーワード（完全一致）
   * @returns 見つかったuid
   */
  getUidFromBody(keyword) {
    const queries = [{ field: 'body', operator: '==', value: keyword }]
    const query = this.generateQuery(queries)
    return this.getRawDocsByQuery(query).then((q) => {
      if (q.empty) return false
      return q.docs[0].ref.parent.parent.id
    })
  }
}

/**
 * @extends {BaseCollection<IWorkModel>}
 */
class SurveyCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'SURVEY'
  }
}

class RegistSurveyAnswerCollection extends SurveyCollection {
  /*
    surveyId : 質問全体のID（登録時質問、〇月振り返りアンケート等）
    /SURVEY内の構造 : /SURVEY/{surveyId}/ANSWER/{uid}
  */
  constructor() {
    super()
    this._ref = 'SURVEY/REGIST/ANSWER/'
  }

  async setSurvey(uid, obj) {
    /*
      uid : uid
      obj : questionIdに対して持たせたい回答オブジェクト{create_reason : hogehoge}等
    */

    let hasOldAnswer = false
    await this.findById(uid).then(function (data) {
      hasOldAnswer = data !== null
    })
    if (hasOldAnswer /*同じuidのデータがもうある？*/) {
      //ある
      this.update(
        uid,
        Object.assign(obj, {
          changeTime: new Date()
        })
      )
    } else {
      //ない
      this.set(
        uid,
        Object.assign(obj, {
          addTime: new Date(),
          uid: uid
        })
      )
    }
  }
}

class WorkCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'WORK'
  }

  /**
   * Return unsubscribe function
   * @param {Object} param
   * @param {string} param.customer
   * @param {string} param.rounder
   * @param {(objects: IWorkModel[]) => void} callback
   * @returns {() => void}
   */

  onUpdatedWithOptions({ customer, rounder }, callback) {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()
    const date = now.getDate()
    let beforeMonth
    if (month == 0) {
      beforeMonth = new Date(`${year - 1}/12/${date}`)
    } else {
      beforeMonth = new Date(`${year}/${month}/${date}`)
    }
    const queries = [{ field: 'createdAt', operator: '>=', value: beforeMonth }]
    const orders = [{ field: 'createdAt', direction: 'desc' }]

    if (customer) {
      queries.push({ field: 'customer', operator: '==', value: customer })
    }
    if (rounder) {
      queries.push({ field: 'rounder', operator: '==', value: rounder })
    }

    const query = this.generateQuery(queries, orders, 200)
    return this.onUpdateDocs(query, callback)
  }

  getAllWorksInToday() {
    const now = new Date()
    const todayStart = new Date(
      `${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()} 01:00:00`
    )
    const queries = [
      { field: 'createdAt', operator: '>=', value: todayStart },
      { field: 'status', operator: '==', value: 'doing' }
    ]
    const query = this.generateQuery(queries)

    return this.getByQuery(query)
  }

  getAll24HoursElapsedWorks() {
    const date24HoursAgo = new Date(
      new Date().setHours(new Date().getHours() - 24)
    )

    const queries = [
      { field: 'createdAt', operator: '<=', value: date24HoursAgo },
      { field: 'status', operator: '==', value: 'doing' },
      { field: 'customer', operator: '==', value: 'charge_spot' }
    ]
    const query = this.generateQuery(queries)

    return this.getByQuery(query)
  }

  getAllWorks1MonthElapsed() {
    // 現在の日付から1ヶ月前の日付を計算
    const date1MonthAgo = new Date()
    date1MonthAgo.setMonth(date1MonthAgo.getMonth() - 1)

    const queries = [
      { field: 'createdAt', operator: '<=', value: date1MonthAgo },
      { field: 'status', operator: '==', value: 'doing' }
    ]
    const query = this.generateQuery(queries)

    return this.getByQuery(query)
  }

  getAllWorksBefore24HoursLimit20(rounder) {
    const date = new Date()
    date.setDate(date.getDate() - 1)

    const queries = [
      { field: 'rounder', operator: '==', value: rounder },
      { field: 'createdAt', operator: '>=', value: date }
    ]
    const orders = [{ field: 'createdAt', direction: 'desc' }]
    const query = this.generateQuery(queries, orders, 20)

    return this.getByQuery(query)
  }

  getAllWorksInPeriod(type, firstDate, lastDate) {
    const queries = [
      { field: type, operator: '>=', value: firstDate },
      { field: type, operator: '<', value: lastDate }
    ]
    const query = this.generateQuery(queries)

    return this.getByQuery(query)
  }

  getAllExportWorksInEndedAtPeriod(firstDate, lastDate) {
    const queries = [
      { field: 'endedAt', operator: '>=', value: firstDate },
      { field: 'endedAt', operator: '<', value: lastDate },
      { field: 'currentWork', operator: '==', value: 'goingToGoalSpot' }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getAllWorksByEndedAt(date) {
    const queries = [{ field: 'endedAt', operator: '>=', value: date }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getAllUserWorksInEndedAtPeriod(uid, firstDate, lastDate) {
    const queries = [
      { field: 'endedAt', operator: '>=', value: firstDate },
      { field: 'endedAt', operator: '<', value: lastDate },
      { field: 'rounder', operator: '==', value: uid }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getCompletedWorkByCategoryAndCustomerInPeriod(
    category,
    customer,
    start,
    end
  ) {
    const queries = [
      { field: 'goalSpot.category', operator: '==', value: category },
      { field: 'customer', operator: '==', value: customer },
      { field: 'endedAt', operator: '>=', value: start },
      { field: 'endedAt', operator: '<', value: end }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getAllToMonth(uid) {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()

    let firstDate = new Date(`${year}/${month + 1}/1`)
    let lastDate
    if (month == 11) {
      lastDate = new Date(`${year + 1}/1/1`)
    } else {
      lastDate = new Date(`${year}/${month + 2}/1`)
    }

    const queries = [
      { field: 'createdAt', operator: '>=', value: firstDate },
      { field: 'createdAt', operator: '<', value: lastDate },
      { field: 'rounder', operator: '==', value: uid }
    ]
    const orders = [{ field: 'createdAt', direction: 'desc' }]

    const query = this.generateQuery(queries, orders)
    return this.getByQuery(query)
  }

  findCustomOrderWork(customer, orderId, uid) {
    const queries = [
      { field: 'customer', operator: '==', value: customer },
      { field: 'orderId', operator: '==', value: orderId },
      { field: 'rounder', operator: '==', value: uid }
    ]
    const query = this.generateQuery(queries)
    return this.getDocByQuery(query)
  }

  getAllGoalSpotWorksInCreatedAtPeriod(firstDate, lastDate) {
    const queries = [
      { field: 'createdAt', operator: '>=', value: firstDate },
      { field: 'createdAt', operator: '<=', value: lastDate }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getWorkByOrderId(orderId, uid) {
    const queries = [
      { field: 'orderId', operator: '==', value: orderId },
      { field: 'rounder', operator: '==', value: uid }
    ]
    const query = this.generateQuery(queries)
    return this.getDocByQuery(query)
  }

  getLookingForOrderWorkByOrderId(orderId, uid) {
    const queries = [
      { field: 'orderId', operator: '==', value: orderId },
      { field: 'status', operator: '==', value: 'doing' },
      { field: 'rounder', operator: '==', value: uid }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getNotDoneLookingForOrders(userId) {
    const queries = [
      { field: 'rounder', operator: '==', value: userId },
      { field: 'status', operator: '==', value: 'doing' },
      { field: 'workType', operator: '==', value: 'lookingForOrder' }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  /**
   * schedulingDateが過ぎているworkを取得
   * @param {Date | undefined} date
   * @returns {Promise<IWorkModel[]>}
   * @memberof WorkCollection
   */
  getAllWorksWhereSchedlingIsExpired(date = new Date()) {
    const queries = [
      { field: 'schedulingDate', operator: '<', value: date },
      { field: 'status', operator: '==', value: 'doing' }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  /**
   * reWorkDeadlineが過ぎているworkを取得
   * @param {Date | undefined} date
   * @returns {Promise<IWorkModel[]>}
   * @memberof WorkCollection
   */
  getAllWorksWhereReWorkDeadlineIsExpired(date = new Date()) {
    const queries = [
      { field: 'reWorkDeadline', operator: '<', value: date },
      { field: 'status', operator: '==', value: 'doing' }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  /**
   * @param {string} id
   * @returns {Promise<IWorkModel[]>}
   */
  getAllReservingWorksByFormat(formatId, userId, customer, fromTime) {
    const queries = [
      { field: 'goalSpot.formatId', operator: '==', value: formatId },
      { field: 'rounder', operator: '==', value: userId },
      { field: 'customer', operator: '==', value: customer },
      { field: 'status', operator: '==', value: 'doing' }
    ]
    if (fromTime) {
      let time = fromTime.toDate ? fromTime.toDate() : fromTime
      queries.push({ field: 'createdAt', operator: '>=', value: time })
    }
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  /**
   * @param {string} id
   * @returns {Promise<IWorkModel[]>}
   */
  getAllWorksByFormat(formatId, userId, customer, fromTime) {
    const queries = [
      { field: 'goalSpot.formatId', operator: '==', value: formatId },
      { field: 'rounder', operator: '==', value: userId },
      { field: 'customer', operator: '==', value: customer }
    ]
    if (fromTime) {
      let time = fromTime.toDate ? fromTime.toDate() : fromTime
      queries.push({ field: 'createdAt', operator: '>=', value: time })
    }
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

/**
 * @extends {BaseCollection<IWorkModel>}
 */
class DeletedWorkCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'DELETED_WORK'
  }

  getAll3MonthlyElapsedWorks() {
    let date3MonthlyAgo = new Date()
    date3MonthlyAgo.setMonth(date3MonthlyAgo.getMonth() - 3)

    const queries = [
      { field: 'deletedAt', operator: '<=', value: date3MonthlyAgo }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

class WorkCollectionGroup extends BaseCollectionGroup {
  constructor() {
    super()
    this._ref = 'WORK'
  }

  /**
   * schedulingDateが過ぎているworkを取得
   * @param {Date | undefined} date
   * @returns {Promise<IWorkModel[]>}
   * @memberof WorkCollectionGroup
   */
  getAllWorksWhereMatchingDateIsExpired(date = new Date()) {
    const queries = [
      { field: 'matchingDate', operator: '<', value: date },
      { field: 'status', operator: '==', value: 'doing' }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

/**
 * @extends {BaseCollection<IWorkModel>}
 */
class DeletedUserCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'DELETED_USER'
  }

  getAll3MonthlyElapsedUsers() {
    let date3MonthlyAgo = new Date()
    date3MonthlyAgo.setMonth(date3MonthlyAgo.getMonth() - 3)

    const queries = [
      { field: 'deletedAt', operator: '<=', value: date3MonthlyAgo }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

/**
 * @extends {BaseCollection<IWorkModel>}
 */
class DeletedUserDepositLogCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'DELETED_USER_DEPOSIT_LOG'
  }
}

/**
 * @extends {BaseCollection<IWorkModel>}
 */
class DeletedSecretCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'DELETED_SECRET'
  }
}

/**
 * @extends {BaseCollection<IWorkModel>}
 */
class DeletedHistoryCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'DELETED_HISTORY'
  }
}

/**
 * @extends {BaseCollection<IHistoryModel>}
 * @property {string} _uid
 */
class HistoryCollection extends BaseCollection {
  /**
   * @param {string} uid
   */
  constructor(uid) {
    super()
    this._ref = 'USER/' + uid + '/HISTORY'
  }

  getWorkId(workId) {
    const queries = [{ field: 'workId', operator: '==', value: workId }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getHistoriesByWorkId(workId) {
    const queries = [{ field: 'workId', operator: '==', value: workId }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getHistoriesByShippingId(shippingId) {
    const queries = [{ field: 'shippingId', operator: '==', value: shippingId }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  /**
   * Return unsubscribe function
   * @param {Object} param
   * @param {(objects: IHistoryModel[]) => void} callback
   * @returns {() => void}
   */
  getHistoriesSnapByLastMonth(callback) {
    const now = new Date()
    let year = now.getFullYear()
    let month = now.getMonth()
    let lastMonthday
    if (month == 0) {
      lastMonthday = new Date(`${year - 1}/12/1 00:00:00+09:00`)
    } else {
      lastMonthday = new Date(`${year}/${month}/1 00:00:00+09:00`)
    }
    const queries = [
      { field: 'createdAt', operator: '>=', value: lastMonthday }
    ]
    const query = this.generateQuery(queries)
    return this.onUpdateDocs(query, callback)
  }

  getOneMonthData(year, month) {
    let firstDate = new Date(year, month, 1, 0, 0)
    let lastDate = fns.startOfDay(fns.addMonths(firstDate, 1))

    const queries = [
      { field: 'createdAt', operator: '>=', value: firstDate },
      { field: 'createdAt', operator: '<', value: lastDate }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getThisYearData() {
    let firstDate = new Date(new Date().getFullYear(), 0, 1, 0, 0)
    let lastDate = fns.startOfDay(fns.addYears(firstDate, 1))

    const queries = [
      { field: 'createdAt', operator: '>=', value: firstDate },
      { field: 'createdAt', operator: '<', value: lastDate }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

class HistoryCollectionGroup extends BaseCollectionGroup {
  constructor() {
    super()
    this._ref = 'HISTORY'
  }

  getHistroriesFromDate(date) {
    const queries = [{ field: 'createdAt', operator: '>=', value: date }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getHistroriesFromStartToEnd(start, end) {
    const queries = [
      { field: 'createdAt', operator: '>=', value: start },
      { field: 'createdAt', operator: '<', value: end }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getByQuery(query) {
    if (_isWebContext) {
      return _webFirestore
        .getDocs(query)
        .then((q) => q.docs.map(this.getDataFromDocumentData))
    } else {
      return query.get().then((q) => q.docs.map(this.getDataFromDocumentData))
    }
  }

  getDataFromDocumentData(doc) {
    // USER/{userId}/HISTORY/{historyId}
    const userId = doc.ref.parent.parent.id
    return {
      ...doc.data(),
      id: doc.id,
      userId
    }
  }
}

class HistoryStatCollection extends BaseCollection {
  /**
   * @param {string} uid
   */
  constructor(uid) {
    super()
    this._ref = 'USER/' + uid + '/HISTORY_STAT'
  }

  async updateByDate(date, data) {
    const year = String(date.getFullYear())
    await this.update(year, data)
  }

  async updateThisYearStat(data) {
    const year = String(new Date().getFullYear())
    return await this.update(year, data)
  }

  async setByDate(date, data) {
    const year = String(date.getFullYear())
    await this.set(year, data)
  }

  async setThisYearStat(data) {
    const year = String(new Date().getFullYear())
    return await this.set(year, data)
  }

  async getThisYearStat() {
    const year = String(new Date().getFullYear())
    return await this.findById(year)
  }

  async getStatByDate(date) {
    const year = String(date.getFullYear())
    return await this.findById(year)
  }
}

class ChargeSpotBonusCollection extends BaseCollection {
  /**
   * @param {string} uid
   */
  constructor(uid) {
    super()
    this._ref = 'USER/' + uid + '/CHARGE_SPOT_BONUS'
  }

  async updateByDate(date, data) {
    const year = String(date.getFullYear())
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const docId = `${year}${month}`
    await this.update(docId, data)
  }

  async updateThisMonth(data) {
    const now = new Date()
    const year = String(now.getFullYear())
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const docId = `${year}${month}`
    return await this.update(docId, data)
  }

  async setByDate(date, data) {
    const year = String(date.getFullYear())
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const docId = `${year}${month}`
    await this.set(docId, data)
  }

  async setThisMonth(data) {
    const now = new Date()
    const year = String(now.getFullYear())
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const docId = `${year}${month}`
    return await this.set(docId, data)
  }

  async getThisMonth() {
    const now = new Date()
    const year = String(now.getFullYear())
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const docId = `${year}${month}`
    return await this.findById(docId)
  }

  async getByDate(date) {
    const year = String(date.getFullYear())
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const docId = `${year}${month}`
    return await this.findById(docId)
  }
  getDocRefByDate(date) {
    const year = String(date.getFullYear())
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const docId = `${year}${month}`
    return this.getDocRef(docId)
  }
}

/**
 * @extends {BaseCollection<ISecretModel>}
 * @property {string} _uid
 */
class SecretCollection extends BaseCollection {
  /**
   * @param {string} uid
   */
  constructor(uid) {
    super()
    this._ref = 'USER/' + uid + '/SECRET'
  }
}

/**
 * @extends {BaseCollection<ISecretModel>}
 * @property {string} _uid
 */
class OrderSecretCollection extends BaseCollection {
  /**
   * @param {string} uid
   */
  constructor(uid) {
    super()
    this._ref = 'ORDER/' + uid + '/ORDER_SECRET'
  }
}

class JoinedMessageRoomCollection extends BaseCollection {
  /**
   * @param {string} uid
   */
  constructor(uid) {
    super()
    this._ref = 'USER/' + uid + '/JOINED_MESSAGE_ROOM'
  }

  findByCompanyIdAndOrderId(companyId, orderId) {
    const queries = [
      { field: 'companyId', operator: '==', value: companyId },
      { field: 'orderId', operator: '==', value: orderId }
    ]
    const query = this.generateQuery(queries)
    return this.getDocByQuery(query)
  }

  getMyMessageRoomSnap(callback) {
    return this.onUpdateDocs(this.collection, callback)
  }
}

class ExpectExportBattetyLogCollection extends BaseCollection {
  /**
   * @param {string} uid
   */
  constructor(uid) {
    super()
    this._ref = 'USER/' + uid + '/EXPECT_EXPORT_BATTERY_LOG'
  }

  getStatus(status) {
    const queries = [{ field: 'status', operator: '==', value: status }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getStatus1Week(status) {
    let dt = new Date()
    dt.setDate(dt.getDate() - 7)
    const queries = [
      { field: 'status', operator: '==', value: status },
      { field: 'updatedAt', operator: '>', value: dt }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
  getAllBefore1Month() {
    let thisMonthStart = new Date()
    thisMonthStart.setHours(0)
    thisMonthStart.setMinutes(0)
    thisMonthStart.setSeconds(0)
    thisMonthStart.setDate(0)
    let firstDate = new Date(thisMonthStart.setMonth(thisMonthStart.getMonth()))
    firstDate = new Date(firstDate.setDate(1))
    let lastDate = new Date(thisMonthStart.setMonth(thisMonthStart.getMonth()))

    lastDate.setHours(23)
    lastDate.setMinutes(59)
    lastDate.setSeconds(59)
    lastDate = new Date(lastDate.setDate(thisMonthStart.getDate()))

    const queries = [
      { field: 'createdAt', operator: '>=', value: firstDate },
      { field: 'createdAt', operator: '<=', value: lastDate }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

class NonComplientCollection extends BaseCollection {
  /**
   * @param {string} uid
   */
  constructor(uid) {
    super()
    this._ref = 'USER/' + uid + '/NON_COMPLIENT'
  }

  getAllToMonth() {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()

    let firstDate = new Date(`${year}/${month + 1}/1`)
    let lastDate
    if (month == 11) {
      lastDate = new Date(`${year + 1}/1/1`)
    } else {
      lastDate = new Date(`${year}/${month + 2}/1`)
    }

    const queries = [
      { field: 'createdAt', operator: '>=', value: firstDate },
      { field: 'createdAt', operator: '<', value: lastDate }
    ]
    const orders = [{ field: 'createdAt', direction: 'desc' }]
    const query = this.generateQuery(queries, orders)
    return this.getByQuery(query)
  }

  getAllBefore1Month() {
    let thisMonthStart = new Date()
    thisMonthStart.setHours(0)
    thisMonthStart.setMinutes(0)
    thisMonthStart.setSeconds(0)
    thisMonthStart.setDate(0)
    let firstDate = new Date(thisMonthStart.setMonth(thisMonthStart.getMonth()))
    firstDate = new Date(firstDate.setDate(1))
    let lastDate = new Date(thisMonthStart.setMonth(thisMonthStart.getMonth()))

    lastDate.setHours(23)
    lastDate.setMinutes(59)
    lastDate.setSeconds(59)
    lastDate = new Date(lastDate.setDate(thisMonthStart.getDate()))

    const queries = [
      { field: 'createdAt', operator: '>=', value: firstDate },
      { field: 'createdAt', operator: '<=', value: lastDate }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

/**
 * @extends {BaseCollection<ICurrentWorktModel>}
 * @property {string} _uid
 */
class CurrentWorkCollection extends BaseCollection {
  /**
   * @param {string} uid
   */
  constructor(uid) {
    super()
    this._ref = 'USER/' + uid + '/CURRENTWORK'
  }
}

/**
 * @extends {BaseCollection<ILocationModel>}
 * @property {string} _uid
 */
class LocationCollection extends BaseCollection {
  /**
   * @param {string} uid
   */
  constructor(uid) {
    super()
    this._ref = 'USER/' + uid + '/LOCATION'
  }

  async checkSameTimeLocation(date) {
    const after1sec_unix = date.getTime() + 1000
    const after1sec = new Date(after1sec_unix)
    const queries = [
      { field: 'createdAt', operator: '>=', value: date },
      { field: 'createdAt', operator: '<=', value: after1sec },
      { field: 'type', operator: '==', value: 'track' }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  transactionLocation(docId, data) {
    this.firestoreRunTransaction((transaction) => {
      const docRef = this.getDocRef(docId)
      return transaction
        .get(docRef)
        .then((doc) => {
          if (!doc.exists) {
            transaction.set(docRef, data)
          } else {
            throw `Document has been duplicated`
          }
        })
        .then(() => {
          return 'success'
        })
        .catch(() => {
          return 'error'
        })
    })
  }
}

/**
 * @extends {BaseCollection<IReceivedBatteryModel>}
 * @property {string} _uid
 */
class ReceivedBatteryLogCollection extends BaseCollection {
  /**
   * @param {string} uid
   */
  constructor(uid) {
    super()
    this._ref = 'USER/' + uid + '/RECEIVED_BATTERY_LOG'
  }

  getDoingLogs() {
    const queries = [{ field: 'status', operator: '==', value: 'doing' }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getNewReceivedAtAll() {
    const orders = [{ field: 'receivedAt', direction: 'desc' }]
    const query = this.generateQuery(null, orders)
    return this.getByQuery(query)
  }

  getReceivedAtAll() {
    const orders = [{ field: 'receivedAt', direction: 'desc' }]
    const query = this.generateQuery(null, orders)
    return this.getByQuery(query)
  }
}

class ReceivedBatteryLogCollectionGroup extends BaseCollectionGroup {
  constructor() {
    super()
    this._ref = 'RECEIVED_BATTERY_LOG'
  }

  getDataFromDocumentData(doc) {
    const isExist = _isWebContext ? doc.exists() : doc.exists
    if (isExist) {
      return {
        ...doc.data(),
        uid: doc.ref.path.split('/')[1],
        id: doc.id
      }
    } else {
      return null
    }
  }

  getLogsByReceivedAt(date) {
    const queries = [{ field: 'receivedAt', operator: '>=', value: date }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

/**
 * @extends {BaseCollection<IRankModel>}
 * @property {string} _uid
 */
class RankCollection extends BaseCollection {
  /**
   * @param {string} uid
   */
  constructor(uid) {
    super()
    this._ref = 'USER/' + uid + '/RANK'
  }
}

/**
 * @extends {BaseCollection<IRecentWorkModel>}
 */
class RecentWorkCollection extends BaseCollection {
  /**
   * @returns {firebase.firestore.CollectionReference}
   */

  constructor() {
    super()
    this._ref = 'RECENTWORK'
  }

  async getStartedWorkSpots() {
    const now = new Date()
    const before3min_unix = now.getTime() - 180000 //ミリ秒なので(1秒=1000)
    const before3min = new Date(before3min_unix)

    const queries = [{ field: 'startedAt', operator: '>=', value: before3min }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  async getEndedWorkSpots() {
    const now = new Date()
    const before3min_unix = now.getTime() - 180000 //ミリ秒なので(1秒=1000)
    const before3min = new Date(before3min_unix)

    const queries = [{ field: 'endedAt', operator: '>=', value: before3min }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  async getEndedExportBLPBWorkSpots() {
    const now = new Date()
    const before10min_unix = now.getTime() - 60000 * 5 //ミリ秒なので(1秒=1000)
    const before10min = new Date(before10min_unix)

    const queries = [
      { field: 'endedAt', operator: '>=', value: before10min },
      { field: 'workType', operator: '==', value: 'exportBLPB' }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  async getEndedExportBLPBWorkByShopId(shopId) {
    const now = new Date()
    const before10min_unix = now.getTime() - 60000 * 5 //ミリ秒なので(1秒=1000)
    const before10min = new Date(before10min_unix)

    const queries = [
      { field: 'endedAt', operator: '>=', value: before10min },
      { field: 'workType', operator: '==', value: 'exportBLPB' },
      { field: 'shopId', operator: '==', value: shopId }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  async getEndedExportBLPBUserRecentWork(uid) {
    const now = new Date()
    const before10min_unix = now.getTime() - 60000 * 5 //ミリ秒なので(1秒=1000)
    const before10min = new Date(before10min_unix)

    const queries = [
      { field: 'endedAt', operator: '>=', value: before10min },
      { field: 'workType', operator: '==', value: 'exportBLPB' },
      { field: 'userId', operator: '==', value: uid }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  async getRecentStartedUserWork(userId) {
    const now = new Date()
    const before30min_unix = now.getTime() - 60000 * 30 //ミリ秒なので(1秒=1000)
    const before30min = new Date(before30min_unix)

    const queries = [
      { field: 'userId', operator: '==', value: userId },
      { field: 'startedAt', operator: '>=', value: before30min }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  async getRecentStartedUserWorkByShopId(userId, shopId) {
    const now = new Date()
    const before30min_unix = now.getTime() - 60000 * 30 //ミリ秒なので(1秒=1000)
    const before30min = new Date(before30min_unix)

    const queries = [
      { field: 'shopId', operator: '==', value: shopId },
      { field: 'userId', operator: '==', value: userId },
      { field: 'startedAt', operator: '>=', value: before30min }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  async getRecentStartedWorkByShopId(shopId) {
    const now = new Date()
    const before3min_unix = now.getTime() - 180000 //ミリ秒なので(1秒=1000)
    const before3min = new Date(before3min_unix)

    const queries = [
      { field: 'shopId', operator: '==', value: shopId },
      { field: 'startedAt', operator: '>=', value: before3min }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  async getRecentEndedWorkByShopId(shopId) {
    const now = new Date()
    const before3min_unix = now.getTime() - 180000 //ミリ秒なので(1秒=1000)
    const before3min = new Date(before3min_unix)

    const queries = [
      { field: 'shopId', operator: '==', value: shopId },
      { field: 'endedAt', operator: '>=', value: before3min }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

/**
 * @extends {BaseCollection<ISpecification>}
 * @property {string} _uid
 */
class SpecificationCollection extends BaseCollection {
  /**
   * @param {string} uid
   */
  constructor(uid) {
    super()
    this._ref = 'USER/' + uid + '/SPECIFICATION'
  }
}

class UserInvoiceCollection extends BaseCollection {
  /**
   * @param {string} uid
   */
  constructor(uid) {
    super()
    this._ref = 'USER/' + uid + '/INVOICE'
  }
}

class UserPointLogCollection extends BaseCollection {
  /**
   * @param {string} uid
   */
  constructor(uid) {
    super()
    this._ref = 'USER/' + uid + '/POINT_LOG'
  }

  getValidPointLogs() {
    const queries = [{ field: 'status', operator: '==', value: 'valid' }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

class UserDepositLogCollection extends BaseCollection {
  /**
   * @param {string} uid
   */

  constructor(uid) {
    super()
    this._ref = 'USER/' + uid + '/USER_DEPOSIT_LOG'
  }

  getFailedLogs() {
    const queries = [{ field: 'isSuccess', operator: '==', value: false }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getAllThisMonth() {
    const now = new Date()
    const thisMonthStart = new Date(
      `${now.getFullYear()}/${now.getMonth() + 1}/1 00:00:00`
    )
    const thisMonthEnd = fns.endOfDay(fns.lastDayOfMonth(now))

    const queries = [
      { field: 'createdAt', operator: '>=', value: thisMonthStart },
      { field: 'createdAt', operator: '<=', value: thisMonthEnd }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
  getAll60Days() {
    const now = new Date()
    const start = fns.startOfDay(fns.subDays(now, 60))
    console.log('start', start)
    const end = fns.endOfDay(now)
    console.log('end', end)

    const queries = [
      { field: 'createdAt', operator: '>=', value: start },
      { field: 'createdAt', operator: '<=', value: end }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getAllOneMonth(date) {
    const start = new Date(
      `${date.getFullYear()}/${date.getMonth() + 1}/1 00:00:00`
    )
    const end = fns.endOfDay(fns.lastDayOfMonth(date))

    const queries = [
      { field: 'createdAt', operator: '>=', value: start },
      { field: 'createdAt', operator: '<=', value: end }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getAllOneMonthAndType(date, type) {
    const start = new Date(
      `${date.getFullYear()}/${date.getMonth() + 1}/1 00:00:00`
    )
    const end = fns.endOfDay(fns.lastDayOfMonth(date))

    const queries = [
      { field: 'type', operator: '==', value: type },
      { field: 'createdAt', operator: '>=', value: start },
      { field: 'createdAt', operator: '<=', value: end }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getAll3Month() {
    const now = new Date()
    const thisMonthStart = new Date(
      `${now.getFullYear()}/${now.getMonth() + 1}/1 00:00:00`
    )
    const threeMonthAgo = fns.subMonths(thisMonthStart, 3)

    const queries = [
      { field: 'createdAt', operator: '>=', value: threeMonthAgo }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

class UserDepositLogCollectionGroup extends BaseCollectionGroup {
  constructor() {
    super()
    this._ref = 'USER_DEPOSIT_LOG'
  }

  getAllOneMonth(date) {
    const start = new Date(
      `${date.getFullYear()}/${date.getMonth() + 1}/1 00:00:00`
    )
    const end = fns.endOfDay(fns.lastDayOfMonth(date))

    const queries = [
      { field: 'createdAt', operator: '>=', value: start },
      { field: 'createdAt', operator: '<=', value: end }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getAllReceiveLogOneMonth(date) {
    const start = new Date(
      `${date.getFullYear()}/${date.getMonth() + 1}/1 00:00:00`
    )
    const end = fns.endOfDay(fns.lastDayOfMonth(date))

    const queries = [
      { field: 'type', operator: '==', value: 'receive' },
      { field: 'createdAt', operator: '>=', value: start },
      { field: 'createdAt', operator: '<=', value: end }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

class CouponCollection extends BaseCollection {
  /**
   * @param {string} uid
   */
  constructor(uid) {
    super()
    this._ref = 'USER/' + uid + '/COUPON'
  }

  getUsedCoupon(orderId) {
    const queries = [{ field: 'orderId', operator: '==', value: orderId }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

class UserCompanyRelationCollection extends BaseCollection {
  /**
   * @param {string} uid
   */
  constructor(uid) {
    super()
    this._ref = 'USER/' + uid + '/COMPANY_RELATION'
  }
}

/**
 * @extends {BaseCollection<ITickerModel>}
 */
class TickerCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'TICKER'
  }
}

/**
 * @extends {BaseCollection<IBonusSpotModel>}
 */
class BonusSpotCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'BONUSSPOT'
  }
}

/**
 * @extends {BaseCollection<IDepositSpotModel>}
 */
class DepositSpotCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'DEPOSITSPOT'
  }

  getDepositThisMonth() {
    const now = new Date()
    const thisMonthFirst = new Date(
      `${now.getFullYear()}/${now.getMonth() + 1}/1 00:00:00`
    )
    const queries = [
      { field: 'createdAt', operator: '>=', value: thisMonthFirst }
    ]
    const query = this.generateQuery(queries)
    return this.getDocByQuery(query)
  }
}

class SpreadsheetTransactionCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'SPREADSHEET_TRANSACTION'
  }
}
/**
 * @extends {BaseCollection<IOrderModel>}
 */
class OrderCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'ORDER'
  }

  getCancellationQueueOrder(deadline) {
    const queries = [
      { field: 'status', operator: '==', value: 'matched' },
      { field: 'worker', operator: '==', value: 'systemLockOrder' },
      { field: 'deadline', operator: '>=', value: deadline }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getOpenedOrder() {
    const queries = [{ field: 'status', operator: '==', value: 'open' }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getValueNotStatusValueCustomerOrder(status, customer) {
    const queries = [
      { field: 'status', operator: '!=', value: status },
      { field: 'customer', operator: '==', value: customer }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getMatchedOrder() {
    const queries = [{ field: 'status', operator: '==', value: 'matched' }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getCompletedOrder() {
    const queries = [{ field: 'status', operator: '==', value: 'done' }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getByCustomer(customer) {
    const queries = [{ field: 'customer', operator: '==', value: customer }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getCompletedOrderBetween(start, end) {
    const queries = [
      { field: 'status', operator: '==', value: 'done' },
      { field: 'endedAt', operator: '>=', value: start },
      { field: 'endedAt', operator: '<=', value: end }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getUncompletedClientOrder() {
    const queries = [
      { field: 'status', operator: 'in', value: ['open', 'matched'] },
      { field: 'orderType', operator: '==', value: 'client' }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getUncompletedShopOrder() {
    const queries = [
      {
        field: 'status',
        operator: 'in',
        value: ['open', 'matched', 'checking']
      },
      { field: 'orderType', operator: '==', value: 'shopOrder' }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getDoneOrderByCategoryAndUserId(userId, category) {
    const queries = [
      { field: 'status', operator: '==', value: 'done' },
      { field: 'worker', operator: '==', value: userId },
      { field: 'category', operator: '==', value: category }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  applyOrder(orderId, rounderId) {
    return this.firestoreRunTransaction((transaction) => {
      const orderRef = this.getDocRef(orderId)
      return transaction.get(orderRef).then((orderDoc) => {
        if (!orderDoc.exists) {
          throw `Document ${orderId} does not exist`
        }

        if (orderDoc.data().worker !== '') {
          throw `Document has been written by other user`
        }

        transaction.update(orderRef, {
          worker: rounderId,
          status: 'matched'
        })
      })
    })
      .then(() => {
        return 'success'
      })
      .catch((error) => {
        console.error('transaction error', error)
        return 'error'
      })
  }

  /**
   *
   *
   * @param {number} lat
   * @param {number} lng
   * @param {number} radiusInM メートルでの半径
   * @param {number} limit
   * @return {Promise<Object>}
   * @memberof OrderCollectionGroup
   */
  async getUncompletedNearOrder(lat, lng, radiusInM, limit) {
    const bounds = geofire.geohashQueryBounds(
      [parseFloat(lat), parseFloat(lng)],
      radiusInM
    )

    const snapshots = []
    const orders = [{ field: 'geoHash', direction: 'asc' }]
    for (const bound of bounds) {
      const queries = [
        {
          field: 'status',
          operator: 'in',
          value: ['open', 'matched', 'checking']
        }
      ]
      const thisQueryLimit = limit

      const startAt = bound[0]
      const endAt = bound[1]

      const query = this.generateQuery(
        queries,
        orders,
        thisQueryLimit,
        startAt,
        endAt
      )

      const result = await this.getByQuery(query)
      snapshots.push(...result)
    }

    const matchingDocs = []
    for (const snap of snapshots) {
      if (matchingDocs.findIndex((doc) => doc.id === snap.id) === -1) {
        matchingDocs.push(snap)
      }
    }

    return matchingDocs
  }

  /**
   * workId に基づいてオーダードキュメントを取得
   * @param {string} workId
   * @returns {Promise<IOrderModel[]>}
   */
  async findByWorkId(workId) {
    const queries = [{ field: 'workId', operator: '==', value: workId }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  deleteByRef(ref) {
    const opWithRef = new OperationsByRef(ref)
    return opWithRef.delete()
  }
}

/**
 * @extends {BaseCollection<IWorkModel>}
 */
class DeletedOrderCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'DELETED_ORDER'
  }

  getAll3MonthlyElapsedWorks() {
    let date3MonthlyAgo = new Date()
    date3MonthlyAgo.setMonth(date3MonthlyAgo.getMonth() - 3)

    const queries = [
      { field: 'deletedAt', operator: '<=', value: date3MonthlyAgo }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}
/**
 * @extends {BaseCollection<IOrderModel>}
 */
class OrderCollectionGroup extends BaseCollectionGroup {
  constructor() {
    super()
    this._ref = 'ORDER'
  }

  getOpenedOrder() {
    const queries = [{ field: 'status', operator: '==', value: 'open' }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  /**
   * statusがopenかmatchedの注文を取得する
   *
   * @return {Promise<IOrderModel[]>}
   * @memberof OrderCollectionGroup
   */
  getUncompletedOrder() {
    const queries = [
      { field: 'status', operator: 'in', value: ['open', 'matched'] }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getUncompletedClientOrder() {
    const queries = [
      { field: 'status', operator: 'in', value: ['open', 'matched'] },
      { field: 'orderType', operator: '==', value: 'client' }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getByCustomerAndOrderId(customer, orderId) {
    const queries = [
      { field: 'orderId', operator: '==', value: orderId },
      { field: 'customer', operator: '==', value: customer }
    ]
    const query = this.generateQuery(queries)
    return this.getDocByQuery(query)
  }
  /**
   *
   *
   * @param {number} lat
   * @param {number} lng
   * @param {number} radiusInM メートルでの半径
   * @param {number} limit
   * @return {Promise<Object>}
   * @memberof OrderCollectionGroup
   */
  async getUncompletedNearOrder(lat, lng, radiusInM, limit) {
    const bounds = geofire.geohashQueryBounds(
      [parseFloat(lat), parseFloat(lng)],
      radiusInM
    )

    const snapshots = []
    const orders = [{ field: 'geoHash', direction: 'asc' }]
    for (const bound of bounds) {
      const queries = [
        {
          field: 'status',
          operator: 'in',
          value: ['open', 'matched']
        }
      ]
      const thisQueryLimit = limit

      const startAt = bound[0]
      const endAt = bound[1]

      const query = this.generateQuery(
        queries,
        orders,
        thisQueryLimit,
        startAt,
        endAt
      )

      const result = await this.getByQuery(query)
      snapshots.push(...result)
    }

    const matchingDocs = []
    for (const snap of snapshots) {
      if (matchingDocs.findIndex((doc) => doc.id === snap.id) === -1) {
        matchingDocs.push(snap)
      }
    }

    return matchingDocs
  }

  async getUncompletedLookingForOrder() {
    const queries = [
      {
        field: 'status',
        operator: '==',
        value: 'open'
      },
      {
        field: 'orderType',
        operator: '==',
        value: 'lookingForOrder'
      }
    ]

    const query = this.generateQuery(queries)

    const result = await this.getByQuery(query)
    return result
  }

  async getMatchingDeadlineExpiredOrder(from) {
    const queries = [
      { field: 'status', operator: '==', value: 'open' },
      { field: 'matchingDeadline', operator: '<=', value: from }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  async findByWorkId(workId) {
    const queries = [{ field: 'workId', operator: '==', value: workId }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  deleteByRef(ref) {
    const opWithRef = new OperationsByRef(ref)
    return opWithRef.delete()
  }
}

class MatchingHistoryCollectionGroup extends BaseCollectionGroup {
  constructor() {
    super()
    this._ref = 'MATCHING_HISTORY'
  }
}

/**
 * @extends {BaseCollection<IReservedSpotModel>}
 */
class ReservedSpotCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'RESERVED_SPOT'
  }

  getReservedSpot() {
    const now = new Date()
    const before1hour_unix = now.getTime() - 3900000 //ミリ秒なので(60秒*1000), 予約トラブルを避けるため5分追加
    const before1hour = new Date(before1hour_unix)

    const queries = [
      { field: 'createdAt', operator: '>=', value: before1hour },
      { field: 'status', operator: '==', value: 'valid' }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getReservedSpotByUserId(uid) {
    const now = new Date()
    const before1hour_unix = now.getTime() - 3600000 //ミリ秒なので(60秒*1000)
    const before1hour = new Date(before1hour_unix)

    const queries = [
      { field: 'createdAt', operator: '>=', value: before1hour },
      { field: 'status', operator: '==', value: 'valid' },
      { field: 'rounder', operator: '==', value: uid }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getRecentMyReservedSpot(uid) {
    const now = new Date()
    const before6hour_unix = now.getTime() - 3600000 * 6 //ミリ秒なので(60秒*1000)
    const before6hour = new Date(before6hour_unix)

    const queries = [
      { field: 'createdAt', operator: '>=', value: before6hour },
      { field: 'rounder', operator: '==', value: uid }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getReservedSpotByShopId(shopId) {
    const now = new Date()
    const before1hour_unix = now.getTime() - 3600000 //ミリ秒なので(60秒*1000)
    const before1hour = new Date(before1hour_unix)

    const queries = [
      { field: 'createdAt', operator: '>=', value: before1hour },
      { field: 'status', operator: '==', value: 'valid' },
      { field: 'shopId', operator: '==', value: shopId }
    ]
    const query = this.generateQuery(queries, null, 1)
    return this.getByQuery(query)
  }
}

class RecentReservedSpotCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'RECENT_RESERVED_SPOT'
  }
}

class MessageRoomCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'MESSAGE_ROOM'
  }

  /**
   * Return unsubscribe function
   * @param {Object} param
   * @param {(objects: IMessageRoomModel[]) => void} callback
   * @returns {() => void}
   */
  getMyMessageRoomSnap(uid, callback) {
    const queries = [{ field: 'workerId', operator: '==', value: uid }]
    const query = this.generateQuery(queries)
    return this.onUpdateDocs(query, callback)
  }
}

class MessageCollection extends BaseCollection {
  /**
   * @param {string} roomId
   */
  constructor(roomId) {
    super()
    this._ref = 'MESSAGE_ROOM/' + roomId + '/MESSAGE'
  }

  getScheduleMessage() {
    const queries = [{ field: 'type', operator: '==', value: 'schedule' }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  onUpdatedWithOptions(callback) {
    const orders = [{ field: 'createdAt', direction: 'desc' }]
    const query = this.generateQuery(null, orders)
    return this.onUpdateDocs(query, callback)
  }

  gerUnReadMessagesByUid(uid) {
    const queries = [
      { field: 'hasRead', operator: '==', value: false },
      { field: 'createdBy', operator: '==', value: uid }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}
class VariableCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'VARIABLE'
  }
}

class ShopCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'SHOP'
  }

  searchByDeviceId(deviceId) {
    const queries = [
      { field: 'deviceIds', operator: 'array-contains', value: deviceId }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

class InvitationCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'INVITATION'
  }

  getMyInvitation(uid) {
    const queries = [{ field: 'acceptedUid', operator: '==', value: uid }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

class InfoCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'INFO'
  }

  getByWorkId(workId) {
    const orders = [{ field: 'workId', operator: '==', value: workId }]
    const query = this.generateQuery(orders)
    return this.getByQuery(query)
  }

  getInfo() {
    const orders = [{ field: 'createdAt', direction: 'desc' }]
    const query = this.generateQuery(null, orders)
    return this.getByQuery(query)
  }

  getInfoAlert(sendType, noticePlace) {
    const queries = [
      { field: 'sendType', operator: 'in', value: sendType },
      { field: 'noticePlace', operator: 'in', value: noticePlace }
    ]
    const orders = [{ field: 'createdAt', direction: 'desc' }]
    const query = this.generateQuery(queries, orders)
    return this.getByQuery(query)
  }

  selectOldCollections() {
    const query = [
      {
        field: 'endedAt',
        operator: '<',
        value: new Date()
      }
    ]
    const q = this.generateQuery(query)
    return this.getByQuery(q)
  }

  getInfoAlertWithSelectedUser(sendType, noticePlace, userId) {
    const queries = [
      { field: 'sendType', operator: 'in', value: sendType },
      { field: 'noticePlace', operator: 'in', value: noticePlace },
      { field: 'selectedUsers', operator: 'array-contains', value: userId }
    ]
    const orders = [{ field: 'createdAt', direction: 'desc' }]
    const query = this.generateQuery(queries, orders)
    return this.getByQuery(query)
  }
}

class OccupationCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'OCCUPATION'
  }
}

class IndustryCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'INDUSTRY'
  }
}

class BatteryShippingFormCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'BATTERY_SHIPPING_FORM'
  }

  getStatusAndUserIdAll(uid, status) {
    const queries = [
      { field: 'userId', operator: '==', value: uid },
      { field: 'status', operator: '==', value: status }
    ]
    const orderby = [{ field: 'createdAt', direction: 'desc' }]
    const query = this.generateQuery(queries, orderby)
    return this.getByQuery(query)
  }

  getDoneFormInPeriod(start, end) {
    const queries = [
      { field: 'status', operator: '==', value: 'done' },
      { field: 'updatedAt', operator: '>=', value: start },
      { field: 'updatedAt', operator: '<', value: end }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getDoneUserFormInPeriod(uid, start, end) {
    const queries = [
      { field: 'userId', operator: '==', value: uid },
      { field: 'status', operator: '==', value: 'done' },
      { field: 'updatedAt', operator: '>=', value: start },
      { field: 'updatedAt', operator: '<', value: end }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

class InsertErrorReportFormCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'INSERT_ERROR_REPORT_FORM'
  }

  getWorkId(workId) {
    const queries = [{ field: 'workId', operator: '==', value: workId }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getImageMd5Hash(imageMd5Hash) {
    const queries = [
      { field: 'imageMd5Hash', operator: '==', value: imageMd5Hash }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getAllToday(uid) {
    let todayStart = new Date()
    todayStart.setHours(0)
    todayStart.setMinutes(0)
    todayStart.setSeconds(0)

    const queries = [
      { field: 'createdAt', operator: '>=', value: todayStart },
      { field: 'userId', operator: '==', value: uid }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getAllToMonth(uid) {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()

    let firstDate = new Date(`${year}/${month + 1}/1`)
    let lastDate
    if (month == 11) {
      lastDate = new Date(`${year + 1}/1/1`)
    } else {
      lastDate = new Date(`${year}/${month + 2}/1`)
    }

    const queries = [
      { field: 'createdAt', operator: '>=', value: firstDate },
      { field: 'createdAt', operator: '<', value: lastDate },
      { field: 'userId', operator: '==', value: uid }
    ]
    const orders = [{ field: 'createdAt', direction: 'desc' }]
    const query = this.generateQuery(queries, orders)
    return this.getByQuery(query)
  }

  getAllBefore1Month(uid) {
    let thisMonthStart = new Date()
    thisMonthStart.setHours(0)
    thisMonthStart.setMinutes(0)
    thisMonthStart.setSeconds(0)
    thisMonthStart.setDate(0)
    let firstDate = new Date(thisMonthStart.setMonth(thisMonthStart.getMonth()))
    firstDate = new Date(firstDate.setDate(1))
    let lastDate = new Date(thisMonthStart.setMonth(thisMonthStart.getMonth()))

    lastDate.setHours(23)
    lastDate.setMinutes(59)
    lastDate.setSeconds(59)
    lastDate = new Date(lastDate.setDate(thisMonthStart.getDate()))

    const queries = [
      { field: 'createdAt', operator: '>=', value: firstDate },
      { field: 'createdAt', operator: '<=', value: lastDate },
      { field: 'userId', operator: '==', value: uid }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getAllInPeriod(uid, start, end) {
    const queries = [
      { field: 'createdAt', operator: '>=', value: start },
      { field: 'createdAt', operator: '<', value: end },
      { field: 'userId', operator: '==', value: uid }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getUserAll(uid) {
    const queries = [{ field: 'userId', operator: '==', value: uid }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

class BatteryLogCollection extends BaseCollection {
  /**
   * @param {string} pbId
   */
  constructor(pbId) {
    super()
    this._ref = 'BATTERY/' + pbId + '/BATTERY_LOG'
  }

  getBatteryLogUserId(userId) {
    const queries = [{ field: 'userId', operator: '==', value: userId }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

class BatteryCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'BATTERY'
  }

  getAllByUserId(userId) {
    const queries = [
      { field: 'userId', operator: '==', value: userId },
      { field: 'status', operator: 'in', value: ['exported', 'exportBLPB'] }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getInsertedBatteries(pbIds) {
    const queries = [{ field: 'pbId', operator: 'in', value: pbIds }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getUserHavingBatteries(userId) {
    const queries = [
      {
        field: 'userId',
        operator: '==',
        value: userId
      },
      {
        field: 'status',
        operator: '==',
        value: 'exported'
      }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getUserIdStatus(userId, status) {
    const queries = [
      { field: 'userId', operator: '==', value: userId },
      { field: 'status', operator: '==', value: status }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getLeftFor2WeeksAll() {
    let dt = new Date()
    dt.setDate(dt.getDate() - 14)
    const queries = [{ field: 'updatedAt', operator: '>', value: dt }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getLeftFor2WeeksUserId(userId) {
    let dt = new Date()
    dt.setDate(dt.getDate() - 14)
    const queries = [
      { field: 'userId', operator: '==', value: userId },
      { field: 'updatedAt', operator: '>', value: dt }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  batchUpdateIsShowFromBatteryIds(selectedBatteryIds, updateToTrue) {
    const updatePromises = selectedBatteryIds.map((batteryId) => {
      return this.update(batteryId, { isShow: updateToTrue })
    })
    return Promise.all(updatePromises)
  }
}

class FocusInsertSpot extends BaseCollection {
  constructor() {
    super()
    this._ref = 'FOCUS_INSERT_SPOT'
  }
}

class ApiErrorLogCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'API_ERROR_LOG'
  }
}

class ScheduleActionCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'SCHEDULE_ACTION'
  }
  getArbitraryMinutesAgo(minutes = 5) {
    let filterActionStartedAt = new Date()
    filterActionStartedAt.setMinutes(
      filterActionStartedAt.getMinutes() - minutes
    )
    const queries = [
      { field: 'actionStartedAt', operator: '>=', value: filterActionStartedAt }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
  getByMinutesAndStatus(minutes = 1, status = 'preparing') {
    let filterActionStartedAt = new Date()
    filterActionStartedAt.setMinutes(
      filterActionStartedAt.getMinutes() - minutes
    )
    const queries = [
      {
        field: 'actionStartedAt',
        operator: '<',
        value: filterActionStartedAt
      },
      { field: 'status', operator: '==', value: status }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
  getWorkIdAndStatusAndActionName(
    workId,
    status = 'preparing',
    actionName = 'workDelete'
  ) {
    const queries = [
      { field: 'status', operator: '==', value: status },
      { field: 'actionName', operator: '==', value: actionName },
      { field: 'workId', operator: '==', value: workId }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
  getWorkIdAndStatus(workId, status = 'preparing') {
    const queries = [
      { field: 'status', operator: '==', value: status },
      { field: 'workId', operator: '==', value: workId }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

class CampaignCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'CAMPAIGN'
  }

  getCampaignName(campaignName) {
    const queries = [
      { field: 'campaignName', operator: '==', value: campaignName }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getCampaignByStartAtInPeriod(start, end) {
    const queries = [
      { field: 'startAt', operator: '>=', value: start },
      { field: 'startAt', operator: '<', value: end }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getCampaignByEndAtInPeriod(start, end) {
    const queries = [
      { field: 'endAt', operator: '>=', value: start },
      { field: 'endAt', operator: '<', value: end }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

class CampaignLogCollection extends BaseCollection {
  /**
   * @param {string} campaignId
   */
  constructor(campaignId) {
    super()
    this._ref = 'CAMPAIGN/' + campaignId + '/CAMPAIGN_LOG'
  }

  getUserIdAndWinningName(userId, winningName) {
    const queries = [
      { field: 'winningName', operator: '==', value: winningName },
      { field: 'userId', operator: '==', value: userId }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getUserId(userId) {
    const queries = [{ field: 'userId', operator: '==', value: userId }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
  getWorkId(workId) {
    const queries = [{ field: 'workId', operator: '==', value: workId }]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  // 期間内の当選履歴を取得
  getWinningLogByPeriod(start, end) {
    const queries = [
      { field: 'updatedAt', operator: '>=', value: start },
      { field: 'updatedAt', operator: '<', value: end }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
  getByCampaingIdAndInUpdatedAtPeriod(
    campaignId,
    updatedAtStart,
    updatedAtEnd,
    winningName
  ) {
    const queries = [
      { field: 'campaignId', operator: '==', value: campaignId },
      { field: 'updatedAt', operator: '>=', value: updatedAtStart },
      { field: 'updatedAt', operator: '<=', value: updatedAtEnd },
      { field: 'winningName', operator: '==', value: winningName }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }

  getByWinningNameForDailyPerUserLimit(winningName, userId) {
    let nowDate = new Date()
    let todayStart = new Date(
      nowDate.getFullYear(),
      nowDate.getMonth(),
      nowDate.getDate(),
      0,
      0,
      0
    )
    let todayEnd = new Date(
      nowDate.getFullYear(),
      nowDate.getMonth(),
      nowDate.getDate(),
      23,
      59,
      59
    )
    const queries = [
      { field: 'winningName', operator: '==', value: winningName },
      { field: 'userId', operator: '==', value: userId },
      { field: 'updatedAt', operator: '>=', value: todayStart },
      { field: 'updatedAt', operator: '<=', value: todayEnd }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

class CampaignWinningAmountCollection extends BaseCollection {
  /**
   * @param {string} campaignId
   */
  constructor(campaignId) {
    super()
    this._ref = 'CAMPAIGN/' + campaignId + '/CAMPAIGN_WINNING_AMOUNT'
  }

  addLotteryResult(workId, userId, winningProbability) {
    const rand = Math.floor(Math.random() * 100)
    let lotteryResult = 'lost'
    let rate = 0
    for (const prop in winningProbability) {
      rate += winningProbability[prop]
      if (rand <= rate) {
        console.log(rate)
        console.log(rand)
        lotteryResult = prop
        break
      }
    }
    console.log('lotteryResult', lotteryResult)
    const CampaignRef = this.getDocRef(String(lotteryResult))
    return firestoreRunTransaction((transaction) => {
      return transaction.get(CampaignRef).then((campaignDoc) => {
        if (!campaignDoc.exists) {
          throw `ドキュメントないです${lotteryResult}`
        }

        let data = campaignDoc.data()
        if (lotteryResult === 'lost') {
          transaction.update(this.getDocRef('lost'), {
            userId: userId,
            workId: workId,
            updatedAt: new Date()
          })
          return ['lost']
        }
        if (data.countWinning >= data.countMaxWinning) {
          delete winningProbability[lotteryResult]
          if (Object.keys(winningProbability).length) {
            return ['rerun', winningProbability]
          } else {
            transaction.update(this.getDocRef('lost'), {
              userId: userId,
              workId: workId,
              updatedAt: new Date()
            })
            return ['lost']
          }
        } else {
          if (data.maxWinningCountByUserPerDay >= 1) {
            return new CampaignLogCollection(data.campaignId)
              .getByWinningNameForDailyPerUserLimit(lotteryResult, userId)
              .then(async (campaignLogData) => {
                if (campaignLogData.length === 0) {
                  new CampaignWinningAmountCollection(data.campaignId).update(
                    lotteryResult,
                    {
                      userId: userId,
                      workId: workId,
                      updatedAt: new Date()
                    }
                  )
                  return ['ok', [lotteryResult]]
                } else if (
                  campaignLogData.length >= data.maxWinningCountByUserPerDay
                ) {
                  delete winningProbability[lotteryResult]
                  if (Object.keys(winningProbability).length) {
                    return ['rerun', winningProbability]
                  } else {
                    new CampaignWinningAmountCollection(data.campaignId).update(
                      'lost',
                      {
                        userId: userId,
                        workId: workId,
                        updatedAt: new Date()
                      }
                    )
                    return ['lost']
                  }
                } else {
                  new CampaignWinningAmountCollection(data.campaignId).update(
                    lotteryResult,
                    {
                      userId: userId,
                      workId: workId,
                      updatedAt: new Date()
                    }
                  )
                  return ['ok', [lotteryResult]]
                }
              })
          } else {
            transaction.update(CampaignRef, {
              userId: userId,
              workId: workId,
              updatedAt: new Date()
            })
            return ['ok', [lotteryResult]]
          }
        }
      })
    })
      .then((value) => {
        return value
      })
      .catch((error) => {
        console.error('transaction error  code ', error)
      })
  }
}

class AdjustRewardCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'ADJUST_REWARD'
  }
}

class FormUsingCompanyCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'FORM_USING_COMPANY'
  }
}

class FormUsingCompanySecretCollection extends BaseCollection {
  constructor(companyId) {
    super()
    this._ref = 'FORM_USING_COMPANY/' + companyId + '/FORM_USING_COMPANY_SECRET'
  }
}

class PartnerInvitationCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'PARTNER_INVITATION'
  }
}

class AccessibleUserCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = 'ACCESSIBLE_USER'
  }
}

class TextFieldAlreadySubmittedCollection extends BaseCollection {
  constructor(orderId) {
    super()
    this._ref = 'ORDER/' + orderId + '/TEXT_FIELD_ALREADY_SUBMITTED'
  }
}

class OrderScheduleCollectionGroup extends BaseCollectionGroup {
  constructor() {
    super()
    this._ref = 'ORDER_SCHEDULE'
  }

  getByNextOrderDate(from) {
    const queries = [
      { field: 'nextOrderDate', operator: '<=', value: from },
      { field: 'status', operator: 'in', value: ['active', 'error'] }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

function increment(count) {
  if (_isWebContext) {
    return _webFirestore.increment(count)
  } else {
    return _FieldValue.increment(count)
  }
}

function arrayUnion(...elements) {
  if (_isWebContext) {
    return _webFirestore.arrayUnion(...elements)
  } else {
    return _FieldValue.arrayUnion(...elements)
  }
}

function arrayRemove(...elements) {
  if (_isWebContext) {
    return _webFirestore.arrayRemove(...elements)
  } else {
    return _FieldValue.arrayRemove(...elements)
  }
}

function serverTimestamp() {
  if (_isWebContext) {
    return _webFirestore.serverTimestamp()
  } else {
    return _FieldValue.serverTimestamp()
  }
}

async function firestoreRunTransaction(callback) {
  if (_isWebContext) {
    return await _webFirestore.runTransaction(
      _firestore,
      async (transaction) => {
        return await callback(transaction)
      }
    )
  } else {
    return _firestore.runTransaction(async (transaction) => {
      return await callback(transaction)
    })
  }
}

function deleteField() {
  if (_isWebContext) {
    return _webFirestore.deleteField()
  } else {
    return _FieldValue.delete()
  }
}

class Batch {
  /**
   * @param {object} _batch
   */
  constructor() {
    if (_isWebContext) {
      this._batch = _webFirestore.writeBatch(_firestore)
    } else {
      this._batch = _firestore.batch()
    }
  }

  set(ref, object) {
    this._batch.set(ref, object)
  }

  update(ref, object) {
    this._batch.update(ref, object)
  }

  delete(ref) {
    this._batch.delete(ref)
  }

  async commit() {
    await this._batch.commit()
  }
}
/**
 *
 *
 * @class OperationsByRef
 */
class OperationsByRef {
  #ref = null

  /**
   * Creates an instance of OperationsByRef.
   * @param {firebase.firestore.DocumentReference} ref
   * @memberof OperationsByRef
   */
  constructor(ref) {
    this.#ref = ref
  }

  delete() {
    if (!this.#ref) return
    if (_isWebContext) {
      // deleteDocはwebFirestroreでのみ使える, refをもとにdocを削除する関数
      // https://firebase.google.com/docs/reference/js/firestore_.md#deletedoc
      return _webFirestore.deleteDoc(this.#ref)
    } else {
      // Node.jsの場合は, refにdeleteというメソッドが生えている
      // https://firebase.google.com/docs/reference/node/firebase.firestore.DocumentReference#delete
      return this.#ref.delete()
    }
  }

  update(data) {
    // setとupdateの違いはこちらを参照
    // https://tomokazu-kozuma.com/difference-between-set-and-update-when-updating-cloud-firestore-data/

    if (!this.#ref) {
      //console.log('ref is null')
      return
    }

    if (_isWebContext) {
      // updateDocはwebFirestroreでのみ使える, refをもとにdocを作成する関数
      // https://firebase.google.com/docs/reference/js/firestore_.md#updatedoc
      return _webFirestore.updateDoc(this.#ref, data)
    } else {
      // Node.jsの場合は, refにupdateというメソッドが生えている
      // https://firebase.google.com/docs/reference/node/firebase.firestore.DocumentReference#update
      return this.#ref.update(data)
    }
  }
}

function getDocRef(path) {
  if (_isWebContext) {
    return _webFirestore.doc(_firestore, path)
  } else {
    return _firestore.doc(path)
  }
}

function removeUndefinedProperties(obj) {
  for (let key in obj) {
    if (obj[key] === undefined) {
      delete obj[key]
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      removeUndefinedProperties(obj[key])
    }
  }
}

/**
 * The whole database
 */
const database = {
  scheduleActionCollection: () => new ScheduleActionCollection(),

  campaignCollection: () => new CampaignCollection(),

  campaignLogCollection: (campaignId) => new CampaignLogCollection(campaignId),

  campaignWinningAmountCollection: (campaignId) =>
    new CampaignWinningAmountCollection(campaignId),

  userCollection: () => new UserCollection(),

  workCollection: () => new WorkCollection(),

  surveyCollection: () => new SurveyCollection(),

  registSurveyAnswerCollection: () => new RegistSurveyAnswerCollection(),

  deletedWorkCollection: () => new DeletedWorkCollection(),

  deletedUserCollection: () => new DeletedUserCollection(),

  deletedUserDepositLogCollection: () => new DeletedUserDepositLogCollection(),

  deletedSecretCollection: () => new DeletedSecretCollection(),

  deletedHistoryCollection: () => new DeletedHistoryCollection(),

  historyCollection: (uid) => new HistoryCollection(uid),

  historyCollectionGroup: () => new HistoryCollectionGroup(),

  historyStatCollection: (uid) => new HistoryStatCollection(uid),

  secretCollection: (uid) => new SecretCollection(uid),

  orderSecretCollection: (uid) => new OrderSecretCollection(uid),

  joinedMessageRoomCollection: (uid) => new JoinedMessageRoomCollection(uid),

  expectExportBattetyLogCollection: (uid) =>
    new ExpectExportBattetyLogCollection(uid),

  NonComplientCollection: (uid) => new NonComplientCollection(uid),

  locationCollection: (uid) => new LocationCollection(uid),

  currentWorkCollection: (uid) => new CurrentWorkCollection(uid),

  specificationCollection: (uid) => new SpecificationCollection(uid),

  userInvoiceCollection: (uid) => new UserInvoiceCollection(uid),

  userDepositLogCollection: (uid) => new UserDepositLogCollection(uid),

  userDepositLogCollectionGroup: () => new UserDepositLogCollectionGroup(),

  couponCollection: (uid) => new CouponCollection(uid),

  receivedBatteryLogCollection: (uid) => new ReceivedBatteryLogCollection(uid),

  rankCollection: (uid) => new RankCollection(uid),

  chargeSpotBonusCollection: (uid) => new ChargeSpotBonusCollection(uid),

  receivedBatteryLogCollectionGroup: () =>
    new ReceivedBatteryLogCollectionGroup(),

  recentWorkCollection: () => new RecentWorkCollection(),

  tickerCollection: () => new TickerCollection(),

  userCompanyRelationCollection: (uid) =>
    new UserCompanyRelationCollection(uid),

  bonusSpotCollection: () => new BonusSpotCollection(),

  depositCollection: () => new DepositSpotCollection(),

  orderCollection: () => new OrderCollection(),

  spreadsheetTransactionCollection: () =>
    new SpreadsheetTransactionCollection(),

  deletedOrderCollection: () => new DeletedOrderCollection(),

  orderCollectionGroup: () => new OrderCollectionGroup(),

  matchingHistoryCollectionGroup: () => new MatchingHistoryCollectionGroup(),

  reservedSpotCollection: () => new ReservedSpotCollection(),

  recentReservedSpotCollection: () => new RecentReservedSpotCollection(),

  messageRoomCollection: () => new MessageRoomCollection(),

  messageCollection: (roomId) => new MessageCollection(roomId),

  variableCollection: () => new VariableCollection(),

  shopCollection: () => new ShopCollection(),

  invitationCollection: () => new InvitationCollection(),

  infoCollection: () => new InfoCollection(),

  occupationCollection: () => new OccupationCollection(),

  industryCollection: () => new IndustryCollection(),

  insertErrorReportFormCollection: () => new InsertErrorReportFormCollection(),

  batteryShippingFormCollection: () => new BatteryShippingFormCollection(),

  batteryCollection: () => new BatteryCollection(),

  batteryLogCollection: (pbId) => new BatteryLogCollection(pbId),

  focusInsertSpot: () => new FocusInsertSpot(),

  apiErrorLogCollection: () => new ApiErrorLogCollection(),

  adjustRewardCollection: () => new AdjustRewardCollection(),

  secretCollectionGroup: () => new SecretCollectionGroup(),

  formUsingCompanyCollection: () => new FormUsingCompanyCollection(),

  formUsingCompanySecretCollection: (companyId) =>
    new FormUsingCompanySecretCollection(companyId),

  partnerInvitationCollection: () => new PartnerInvitationCollection(),

  userMonthlyRewardSummaryCollectionGroup: () =>
    new UserMonthlyRewardSummaryCollectionGroup(),

  accessibleUserCollection: () => new AccessibleUserCollection(),

  textFieldAlreadySubmittedCollection: (orderId) =>
    new TextFieldAlreadySubmittedCollection(orderId),

  orderScheduleCollectionGroup: () => new OrderScheduleCollectionGroup(),

  workCollectionGroup: () => new WorkCollectionGroup(),

  increment: (number) => increment(number),

  deleteField: () => deleteField(),

  arrayUnion: (elements) => arrayUnion(elements),

  arrayRemove: (elements) => arrayRemove(elements),

  serverTimestamp: () => serverTimestamp(),

  runTransaction: async (callback) => await firestoreRunTransaction(callback),

  OperationsByRef: (ref) => new OperationsByRef(ref),

  batch: () => new Batch(),

  getDocRef: (path) => getDocRef(path)
}

/**
 * @param {firebase.firestore.Firestore} firestore
 * @param {Object} param1
 * @param {firebase.firestore.FieldValue} param1.FieldValue
 */
const initializeDatabase = (
  firestore,
  { FieldValue },
  isWebContext = false,
  webFirestore = null
) => {
  console.log('initializeDatabase')
  _FieldValue = FieldValue
  _firestore = firestore
  _isWebContext = isWebContext
  if (_isWebContext) {
    _webFirestore = webFirestore
  }
}

class UserRewardSummaryCollection extends BaseCollection {
  constructor(userId) {
    super()
    this._ref =
      _adminBasePath + '/USER_REWARD/' + userId + '/USER_REWARD_SUMMARY'
  }
}

class UserMonthlyRewardSummaryCollectionGroup extends BaseCollectionGroup {
  constructor() {
    super()
    this._ref = 'USER_REWARD_SUMMARY'
  }

  getHasNotSentMoneyRewardSummary(summaryId) {
    const queries = [
      { field: 'summaryId', operator: '==', value: summaryId },
      { field: 'reconciliation', operator: '==', value: 'nothing' },
      { field: 'nextMonthRemainAmount', operator: '>', value: 0 }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

class UserRewardCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = _adminBasePath + '/USER_REWARD'
  }
}

class RewardSummaryCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = _adminBasePath + '/REWARD_SUMMARY'
  }
}

class FormatCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = _adminBasePath + '/FORMAT'
  }
}

class FormatVersionCollection extends BaseCollection {
  constructor(formatId) {
    super()
    this._ref = _adminBasePath + '/FORMAT/' + formatId + '/FORMAT_VERSION'
  }
}

const adminDb = {
  rewardSummaryCollection: () => new RewardSummaryCollection(),
  userRewardCollection: () => new UserRewardCollection(),
  UserPointLogCollection: () => new UserPointLogCollection(),
  userRewardSummaryCollection: (userId) =>
    new UserRewardSummaryCollection(userId),
  formatCollection: () => new FormatCollection(),
  formatVersionCollection: (formatId) => new FormatVersionCollection(formatId),
  increment: (number) => increment(number),
  batch: () => new Batch()
}

/**
 * 以下法人管理画面向け
 */

class BusinessCompanyCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = _businessBasePath + '/BUSINESS_COMPANY/'
  }
}

class BusinessCompanySecretCollection extends BaseCollection {
  constructor(companyId) {
    super()
    this._ref =
      _businessBasePath +
      '/BUSINESS_COMPANY/' +
      companyId +
      '/BUSINESS_COMPANY_SECRET'
  }
}

class BusinessOrderCollection extends BaseCollection {
  constructor(companyId) {
    super()
    this._ref = _businessBasePath + '/BUSINESS_COMPANY/' + companyId + '/ORDER'
  }
}

class BusinessFormatCollection extends BaseCollection {
  constructor(companyId) {
    super()
    this._ref = _businessBasePath + '/BUSINESS_COMPANY/' + companyId + '/FORMAT'
  }
}

class BusinessFormatVersionCollection extends BaseCollection {
  constructor(companyId, formatId) {
    super()
    this._ref =
      _businessBasePath +
      '/BUSINESS_COMPANY/' +
      companyId +
      '/FORMAT/' +
      formatId +
      '/FORMAT_VERSION'
  }
}

class BusinessInvoiceLogCollection extends BaseCollection {
  constructor(companyId) {
    super()
    this._ref =
      _businessBasePath + '/BUSINESS_COMPANY/' + companyId + '/INVOICE_LOG'
  }
}

class BusinessMessageRoomCollection extends BaseCollection {
  /**
   * @param {string} _companyId
   */
  constructor(companyId) {
    super()
    this._ref =
      _businessBasePath + '/BUSINESS_COMPANY/' + companyId + '/MESSAGE_ROOM'
  }

  findByOrderId(orderId) {
    const queries = [{ field: 'orderId', operator: '==', value: orderId }]
    const query = this.generateQuery(queries, null, 1)
    return this.getDocByQuery(query)
  }
}

class BusinessMessageCollection extends BaseCollection {
  /**
   * @param {string} _companyId
   * @param {string} _roomId
   */

  constructor(companyId, roomId) {
    super()
    this._ref =
      _businessBasePath +
      '/BUSINESS_COMPANY/' +
      companyId +
      '/MESSAGE_ROOM/' +
      roomId +
      '/MESSAGE'
  }

  getCreatedAtAscAll() {
    const orders = [{ field: 'createdAt', direction: 'asc' }]
    const query = this.generateQuery(null, orders)
    return this.getByQuery(query)
  }
}

class BusinessAlreadyReadCollection extends BaseCollection {
  /**
   * @param {string} _companyId
   * @param {string} _roomId
   */

  constructor(companyId, roomId) {
    super()
    this._ref =
      _businessBasePath +
      '/BUSINESS_COMPANY/' +
      companyId +
      '/MESSAGE_ROOM/' +
      roomId +
      '/ALREADY_READ'
  }
}

class BusinessJoinedMessageRoomCollection extends BaseCollection {
  /**
   * @param {string} companyId
   */
  constructor(companyId) {
    super()
    this._ref =
      _businessBasePath +
      '/BUSINESS_COMPANY/' +
      companyId +
      '/JOINED_MESSAGE_ROOM'
  }
}
class BusinessTextFieldAlreadySubmittedCollection extends BaseCollection {
  constructor(companyId, orderId) {
    super()
    this._ref =
      _businessBasePath +
      '/BUSINESS_COMPANY/' +
      companyId +
      '/ORDER/' +
      orderId +
      '/TEXT_FIELD_ALREADY_SUBMITTED'
  }
}

class BusinessMatchingHistoryCollection extends BaseCollection {
  constructor(companyId) {
    super()
    this._ref =
      _businessBasePath + '/BUSINESS_COMPANY/' + companyId + '/MATCHING_HISTORY'
  }

  getSameFormatHistory(formatId, accessibleUserId) {
    const queries = [
      { field: 'formatId', operator: '==', value: formatId },
      { field: 'accessibleUserId', operator: '==', value: accessibleUserId }
    ]
    const query = this.generateQuery(queries)
    return this.getByQuery(query)
  }
}

class BusinessRelatedSpowerCollection extends BaseCollection {
  constructor(companyId) {
    super()
    this._ref =
      _businessBasePath +
      '/BUSINESS_COMPANY/' +
      companyId +
      '/BUSINESS_RELATED_SPOWER'
  }
}

class BusinessOrderScheduleCollection extends BaseCollection {
  constructor(companyId) {
    super()
    this._ref =
      _businessBasePath + '/BUSINESS_COMPANY/' + companyId + '/ORDER_SCHEDULE'
  }

  getByCurrentOrderId(orderId) {
    const queries = [
      { field: 'currentOrderId', operator: '==', value: orderId }
    ]
    const query = this.generateQuery(queries)
    return this.getDocByQuery(query)
  }
}

const businessDb = {
  companyCollection: () => new BusinessCompanyCollection(),
  orderCollection: (companyId) => new BusinessOrderCollection(companyId),
  formatCollection: (companyId) => new BusinessFormatCollection(companyId),
  invoiceLogCollection: (companyId) =>
    new BusinessInvoiceLogCollection(companyId),
  companySecretCollection: (companyId) =>
    new BusinessCompanySecretCollection(companyId),
  messageRoomCollection: (companyId) =>
    new BusinessMessageRoomCollection(companyId),
  messageCollection: (companyId, roomId) =>
    new BusinessMessageCollection(companyId, roomId),
  alreadyReadCollection: (companyId, roomId) =>
    new BusinessAlreadyReadCollection(companyId, roomId),
  joinedMessageRoomCollection: (companyId) =>
    new BusinessJoinedMessageRoomCollection(companyId),
  textFieldAlreadySubmittedCollection: (companyId, orderId) =>
    new BusinessTextFieldAlreadySubmittedCollection(companyId, orderId),
  matchingHistoryCollection: (companyId) =>
    new BusinessMatchingHistoryCollection(companyId),
  relatedSpowerCollection: (companyId) =>
    new BusinessRelatedSpowerCollection(companyId),
  orderScheduleCollection: (companyId) =>
    new BusinessOrderScheduleCollection(companyId),
  formatVersionCollection: (companyId, formatId) =>
    new BusinessFormatVersionCollection(companyId, formatId)
}

class PartnerCollection extends BaseCollection {
  constructor() {
    super()
    this._ref = _partnerBasePath + '/PARTNER'
  }
}

class PartnerConnectedUserCollection extends BaseCollection {
  constructor(partnerId) {
    super()
    this._ref = _partnerBasePath + '/PARTNER/' + partnerId + '/CONNECTED_USER'
  }
}

const partnerDb = {
  partnerCollection: () => new PartnerCollection(),
  partnerConnectedUserCollection: (partnerId) =>
    new PartnerConnectedUserCollection(partnerId)
}

module.exports = {
  database,
  initializeDatabase,
  adminDb,
  businessDb,
  partnerDb
}

import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/spots'
  },
  // {
  //   path: '/sample',
  //   name: 'sample',
  //   component: () => import('@/views/MyPage')
  // },
  // {
  //   path: "/maintenance",
  //   component: () =>
  //     import("@/views/Maintenance")
  // },
  // for rounder
  // {
  //   path: '/redirect_gy',
  //   name: 'redirect_gy',
  //   component: () => import('@/views/campaign/RedirectGmoYahoo')
  // },
  {
    path: '/campaign/yahoo_map_2025',
    name: 'yahoo_map_2025',
    component: () => import('@/views/campaign/CampaignYahooMap2025')
  },
  {
    path: '/campaign/yahoo_map',
    name: 'campaign_yahoo_map',
    component: () => import('@/views/campaign/CampaignYahooMap')
  },
  {
    path: '/campaign/renosy',
    name: 'campaign_renosy',
    component: () => import('@/views/campaign/CampaignRenosy')
  },
  {
    path: '/campaign/april_fool_2024',
    name: 'campaign_april_fool_2024',
    component: () => import('@/views/campaign/AprilFoolErrorRecruitment')
  },
  {
    path: '/employee_benefits/perk_introduction',
    name: 'employee_benefits_perk_introduction',
    component: () => import('@/views/employeeBenefits/PerkIntroduction')
  },
  {
    path: '/tutorial',
    name: 'tutorial',
    component: () => import('@/views/Tutorial')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/RegistUser')
  },
  {
    path: '/spots',
    name: 'spots',
    component: () => import('@/views/Spots')
  },
  {
    path: '/looking_for_orders',
    name: 'looking_for_orders',
    component: () => import('@/views/LookingForOrders')
  },
  {
    path: '/looking_for_orders/:companyId/:orderId',
    name: 'company_looking_for_order_detail',
    component: () => import('@/views/LookingForOrders')
  },
  {
    path: '/looking_for_orders/:orderId',
    name: 'looking_for_order_detail',
    component: () => import('@/views/LookingForOrders')
  },
  {
    path: '/spot/:orderId',
    name: 'spot',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/spots/:shopId/:workType',
    name: 'spot_detail',
    component: () => import('@/views/Spots')
  },
  {
    path: '/orders/:orderId',
    name: 'order_detail',
    component: () => import('@/views/Spots')
  },
  {
    path: '/mypage',
    name: 'mypage',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/mypage/work/:workId',
    name: 'mywork',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/mypage/work/:workId/message',
    name: 'order_message_room',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/mypage/work/:workId/message/review',
    name: 'order_review',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/mypage/specifications',
    name: 'specifications',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/mypage/specifications/:date',
    name: 'specificationDetail',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/mypage/specifications/invoice/:date',
    name: 'specificationInvoice',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/settings/set_ekyc',
    name: 'set_ekyc',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/settings/set_telephone_number',
    name: 'set_telephone_number',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/settings/set_rounder_card',
    name: 'set_rounder_card',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/settings/set_profile',
    name: 'set_profile',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/settings/set_map',
    name: 'set_map',
    component: () => import('@/views/MyPage')
  },
  // {
  //   path: "/settings/set_reward_notify",
  //   name: "set_reward_notify",
  //   component: () =>
  //     import("@/views/MyPage")
  // },
  {
    path: '/settings/set_reward_receive_info',
    name: 'set_reward_receive_info',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/settings/receive_battery',
    name: 'receive_battery',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/settings/receive_battery:pbBattery',
    name: 'receive_battery_pbBattery',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/settings/set_line_id',
    name: 'set_line_id',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/settings/set_come-on_id',
    name: 'set_come-on_id',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/settings/spower_plus',
    name: 'setting_spower_plus',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/reports/insert_error_report_form/',
    name: 'insert_error_report_form',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/reports/insert_exception_error_report_form/',
    name: 'insert_exception_error_report_form',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/reports/insert_error_report_form/:workId',
    name: 'insert_error_report_form_workId',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/reports/insert_exception_error_report_form/:workId',
    name: 'insert_exception_error_report_form_workId',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/reports/battery_shipping_form/',
    name: 'battery_shipping_form',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/reports/usable_battery_shipping_form/',
    name: 'usable_battery_shipping_form',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/reports/battery_shipping_list/',
    name: 'battery_shipping_list',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/r/:r',
    name: 'redirect',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/reports/reported_insert_error_list',
    name: 'reported_insert_error_list',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/spots/search',
    name: 'search',
    component: () => import('@/views/Search')
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import('@/views/SignIn')
  },
  {
    path: '/sign-up/invited/:invitedBy/:campaignId',
    name: 'sign-up-invited',
    component: () => import('@/views/SignIn')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/SignIn')
  },
  {
    path: '/login/:redirectPath',
    name: 'login-redirectPath',
    component: () => import('@/views/SignIn')
  },
  {
    path: '/user-blocked',
    name: 'user-blocked',
    component: () => import('@/views/UserBlocked')
  },
  {
    path: '/terms/:type',
    name: 'terms-privacy',
    component: () => import('@/views/Terms')
  },
  {
    path: '/reading',
    name: 'reading',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/tools/empty_slot_export',
    name: 'empty_slot_export',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/rewards',
    name: 'rewards',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/rewards/receive',
    name: 'reward_receive',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/rewards/list',
    name: 'reward_list',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/battery/list',
    name: 'battery_list',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/message/:messageRoomId',
    name: 'message',
    component: () => import('@/views/MyPage')
  },
  {
    path: '/:catchAll(.*)',
    name: 'notfound',
    redirect: '/spots'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
